import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { sessionInfo } from "../../global/function";
import history from "../../redux/history";
import PrintBooking from "./print-compoents/booking-print.component";
import PrintEvent from "./print-compoents/event-print.component";
import "./print-page.style.scss";

function PrintPage() {
	const location = useLocation();
	const printPage = useRef();
	const [bookingList, setBookingList] = useState();
	const [printInfo, setPrintInfo] = useState("summary");
	const [companyRole, setCompanyRole] = useState("");

	useEffect(() => {
		let eventData = location.state.event.booking_informations;
		let eventPaymentData = location.state.eventPayment.bookingPayments;
		let structuredEvent = [];

		eventData.forEach((booking) => {
			let index = eventPaymentData.findIndex(
				(v) => v.booking_info_id === booking.booking_info_id
			);
			if (index > -1) {
				let structure = {
					...eventPaymentData[index],
					booking_info_id: booking.booking_info_id,
					booked_date_english: booking.booked_date_english,
					booked_date_nepali: booking.booked_date_nepali,
					booked_time: booking.booked_time,
					booking_type: booking.booking_type,
					booking_status: booking.booking_status,
				};
				structuredEvent.push(structure);
			}
		});

		setBookingList(structuredEvent);
	}, [location.state]);

	useEffect(() => {
		async function fetchSessionData() {
			await sessionInfo().then((data) => {
				setCompanyRole(data.company_role);
			});
		}

		fetchSessionData();
	}, []);

	const returnToBooking = () => {
		history.push({
			pathname: "/booking-event",
			search: `?event_id=${location.state.event_id}`,
			state: {
				event_info_id: location.state.event.event_info_id,
				booking_info_id: location.state.booking_data.booking_info_id,
			},
		});
	};

	const _handlePrintInfo = () => {
		printInfo === "summary" && setPrintInfo("invoice");
		printInfo === "invoice" && setPrintInfo("summary");
	};

	return (
		<div className="print-page-wrapper">
			<div className="print-page-action-container">
				<div
					className="bg-default print-button"
					onClick={returnToBooking}
				>
					<span>Back</span>
				</div>
				{companyRole !== "viewer" && (
					<div
						className="bg-default print-button"
						onClick={_handlePrintInfo}
					>
						<span className="text-capitalize">{printInfo}</span>
					</div>
				)}
				<ReactToPrint
					trigger={() => (
						<div className="bg-default print-button">
							<span>Print</span>
						</div>
					)}
					content={() => printPage.current}
				/>
			</div>
			<div className="bg-default print-page-container">
				{location.state.action[0] === "Booking" && (
					<PrintBooking
						printInfo={printInfo}
						ref={printPage}
						printType={location.state.action[1]}
						paymentData={location.state.payment}
						bookingData={location.state.booking_data}
						client={location.state.event}
						companyRole={companyRole}
					/>
				)}
				{location.state.action[0] === "Event" && (
					<>
						<PrintEvent
							printInfo={printInfo}
							ref={printPage}
							client={location.state.event}
							printType={location.state.action[1]}
							event={location.state.event}
							bookingData={bookingList}
							eventPayments={location.state.eventPayment}
							companyRole={companyRole}
						/>
					</>
				)}
			</div>
		</div>
	);
}

export default PrintPage;
