import React, { useState, useEffect } from "react";
import MenuItemContainer from "../../components/add-menu-services/menu-item-container/menu-item-container.component";
import { useSelector } from "react-redux";
import { isEmptyObject } from "../../global/function";
import "./add-booking-menu.style.scss";

function AddBookingMenu({ onClickSave, menuList, menuGroup }) {
	const { bookingData } = useSelector((state) => state.booking);
	const [selectedItems, setSelectedItems] = useState({});
	const [submissionReady, setSubmissionReady] = useState({});
	const [preSaveSelectedItems, setPreSaveSelectedItems] = useState({});
	const [currentmenuGroup, setCurrentMenuGroup] = useState("");
	//- states for display
	const [categoryList, setCategoryList] = useState([
		"snacks",
		"meals",
		"soup",
		"salad",
		"dessert",
	]);
	const [selectedCategory, setSelectedCategory] = useState("");
	const [selectedType, setSelectedType] = useState("");
	const [search, setSearch] = useState("");

	useEffect(() => {
		let selectedTabs = {
			category: "",
			type: "",
		};

		function menuViewTabSelector() {
			let selection = false;
			let count = 0;
			//Loop thought main menu items to set Starter: Category and Type to desplay
			while (!selection && count <= categoryList.length) {
				if (isEmptyObject(menuList[categoryList[count]])) {
					count++;
				} else {
					//starter Category and Type
					selectedTabs.category = categoryList[count];
					selectedTabs.type = Object.keys(
						menuList[categoryList[count]]
					)[0];
					selection = true;
				}
			}
			return selection;
		}

		if (
			currentmenuGroup !== menuGroup &&
			!isEmptyObject(menuList) &&
			!isEmptyObject(bookingData)
		) {
			let menuListExists = menuViewTabSelector();
			if (
				menuListExists &&
				menuList[selectedTabs.category][selectedTabs.type][0]
					.menu_group === menuGroup
			) {
				// --- Start --- sorting booking menu data in format
				if (isEmptyObject(preSaveSelectedItems)) {
					let selectedMenu = {
						snacks: {},
						meals: {},
						soup: {},
						salad: {},
						dessert: {},
					};
					let selectedMenuStore = {
						snacks: {},
						meals: {},
						soup: {},
						salad: {},
						dessert: {},
					};
					let bookingMenu = bookingData.booking_snacks.concat(
						bookingData.booking_desserts
					);

					if (bookingMenu.length > 0) {
						bookingMenu.forEach((item) => {
							if (
								item.item_type in
								selectedMenu[item.menu_category]
							) {
								selectedMenu[item.menu_category][
									item.item_type
								].push(item);
								selectedMenuStore[item.menu_category][
									item.item_type
								].push(item);
							} else {
								selectedMenu[item.menu_category] = {
									...selectedMenu[item.menu_category],
									[item.item_type]: [item],
								};
								selectedMenuStore[item.menu_category] = {
									...selectedMenu[item.menu_category],
									[item.item_type]: [item],
								};
							}
						});
					}
					setSelectedItems(selectedMenu);
					setPreSaveSelectedItems(selectedMenuStore);
				}
				// --- END --- sorting booking menu data in format
				if (isEmptyObject(submissionReady))
					setSubmissionReady({
						delete_booking_snacks: [],
						delete_booking_desserts: [],
						booking_snacks: [],
						booking_desserts: [],
					});
				setSelectedCategory(selectedTabs.category);
				setSelectedType(selectedTabs.type);
				setCurrentMenuGroup(menuGroup);
			}
		}
	}, [
		menuList,
		bookingData,
		currentmenuGroup,
		menuGroup,
		categoryList,
		preSaveSelectedItems,
		submissionReady,
	]);

	//---Start --- Function to control tab selections
	function _handleCategorySelect(v) {
		setSelectedCategory(v);
		let selectKey = Object.keys(menuList[v]);
		selectKey.length > 0
			? setSelectedType(selectKey[0])
			: setSelectedType("None");
	}
	function _handleTypeSelect(v) {
		setSelectedType(v);
	}
	//--End--

	//--Start-- controlling menu item click
	function _handleAddSnacks(item, remove = false) {
		let selection = selectedItems;
		let submit = submissionReady;
		let deleteItem = {};
		let temp;
		//Remove/Add item condition
		if (remove) {
			//REMOVING ITEM
			// -- Start -- filtering and removing item for display
			temp = selection[selectedCategory]?.[selectedType].filter((v) => {
				item.menu_info_id === v.menu_info_id && (deleteItem = { ...v });
				return item.menu_info_id !== v.menu_info_id;
			});
			//controls type label to remove empty types
			if (temp.length === 0) {
				delete selection[selectedCategory][selectedType];
			} else selection[selectedCategory][selectedType] = temp;
			// -- End -- filtering and removing item for display

			if (item.menu_category === "dessert") {
				/*Placing item ready to send to BE if dessert
				if item exists in add list ==> remove ; else => + in delete list*/
				if (
					submit.booking_desserts.some(
						(i) => i.menu_info_id === item.menu_info_id
					)
				) {
					submit.booking_desserts = submit.booking_desserts.filter(
						(i) => item.menu_info_id !== i.menu_info_id
					);
				} else submit.delete_booking_desserts.push(deleteItem);
			} else {
				/*Placing item ready to send to BE if Snacks
				if item exists in add list ==> remove ; else => + in delete list*/
				if (
					submit.booking_snacks.some(
						(i) => i.menu_info_id === item.menu_info_id
					)
				) {
					submit.booking_snacks = submit.booking_snacks.filter(
						(i) => item.menu_info_id !== i.menu_info_id
					);
				} else submit.delete_booking_snacks.push(deleteItem);
			}
		} else {
			//ADDING ITEM
			let addItem = item;
			let x = preSaveSelectedItems?.[selectedCategory]?.[selectedType];

			temp =
				x &&
				x.some((v) => {
					item.menu_info_id === v.menu_info_id &&
						(addItem = { ...v });
					return item.menu_info_id === v.menu_info_id;
				});
			//controls type label to add new types
			if (selectedType in selection[selectedCategory]) {
				selection[selectedCategory][selectedType].push(addItem);
			} else {
				selection[item.menu_category] = {
					...selection[item.menu_category],
					[item.item_type]: [addItem],
				};
			}
			if (item.menu_category === "dessert") {
				/*Placing item ready to send to BE if dessert
				if item exists in delete list ==> remove ; else => + in add list*/
				if (
					submit.delete_booking_desserts.some(
						(i) => i.menu_info_id === item.menu_info_id
					)
				) {
					submit.delete_booking_desserts =
						submit.delete_booking_desserts.filter(
							(i) => item.menu_info_id !== i.menu_info_id
						);
				} else submit.booking_desserts.push(item);
			} else {
				/*Placing item ready to send to BE if snacks
				if item exists in delete list ==> remove ; else => + in add list*/
				if (
					submit.delete_booking_snacks.some(
						(i) => i.menu_info_id === item.menu_info_id
					)
				) {
					submit.delete_booking_snacks =
						submit.delete_booking_snacks.filter(
							(i) => item.menu_info_id !== i.menu_info_id
						);
				} else submit["booking_snacks"].push(item);
			}
		}
		setSelectedItems({ ...selection });
		setSubmissionReady({ ...submit });
	}
	//--End--

	//Remder items under type
	const _renderDisplayMenu = () => {
		let menuSelectedCategory = menuList[selectedCategory][selectedType];
		let menuSelectedType = [];

		//Filters if search entered
		function _filterItems(item) {
			if (search !== "") {
				return item.menu_item.toLowerCase().includes(search);
			} else return true;
		}

		//list items by selected Type to highlight
		if (selectedType in selectedItems[selectedCategory]) {
			menuSelectedType = selectedItems[selectedCategory][selectedType];
		}

		return (
			<div className="menu-display-items">
				{menuSelectedCategory ? (
					menuSelectedCategory
						.filter((i) => _filterItems(i))
						?.map((item) => (
							<MenuItemContainer
								type={
									menuSelectedType.some(
										(v) =>
											item.menu_info_id === v.menu_info_id
									)
										? "selected"
										: item.type
								}
								item={item}
								key={item.menu_info_id}
								addOnClick={_handleAddSnacks}
								fromBooking={true}
							/>
						))
				) : (
					<div className="empty-items">No Items Exist</div>
				)}
			</div>
		);
	};

	const handleSave = () => {
		let submitPayload = submissionReady;
		let deleteDessertsPayload = submitPayload.delete_booking_desserts.map(
			({ booking_dessert_id }) => booking_dessert_id
		);
		submitPayload.delete_booking_desserts = deleteDessertsPayload;

		let deleteSnacksPayload = submitPayload.delete_booking_snacks.map(
			({ booking_snack_id }) => booking_snack_id
		);
		submitPayload.delete_booking_snacks = deleteSnacksPayload;
		onClickSave(submitPayload, "menu");
	};

	return (
		<>
			{!isEmptyObject(menuList) && selectedCategory !== "" && (
				<div className="bg-default inner-container full mt-56px mb-15px justify-center">
					<div className="label-container">
						<div className="clickable-types main-list">
							{categoryList.map((cat, key) => (
								<div
									className={`menu-label-button ${
										isEmptyObject(menuList[cat])
											? ""
											: "with-val"
									} ${
										selectedCategory === cat && "displayed"
									}`}
									key={key}
									onClick={() => _handleCategorySelect(cat)}
								>
									<div className="label-menu-name">
										{cat}{" "}
									</div>
									{Object.values(selectedItems[cat]).flat()
										.length > 0 && (
										<div className="label-menu-count">
											{
												Object.values(
													selectedItems[cat]
												).flat().length
											}
										</div>
									)}
								</div>
							))}
						</div>
					</div>
					<div className="label-container">
						<div className="clickable-types">
							{Object.keys(menuList[selectedCategory])?.map(
								(type, key) => (
									<div
										className={`menu-label-button ${
											isEmptyObject(
												menuList[selectedCategory]?.[
													type
												]
											)
												? ""
												: "with-val"
										} ${
											selectedType === type && "displayed"
										}`}
										key={key}
										onClick={() => _handleTypeSelect(type)}
									>
										<div className="label-menu-name">
											{type}{" "}
										</div>
										{selectedItems[selectedCategory]?.[
											type
										] ? (
											<div className="label-menu-count">
												{
													selectedItems[
														selectedCategory
													]?.[type].length
												}
											</div>
										) : (
											""
										)}
									</div>
								)
							)}
						</div>
						<div className="menu-filter-search">
							<input
								value={search}
								placeholder={"Search"}
								onChange={(e) =>
									setSearch(e.target.value.toLowerCase())
								}
							></input>
							<button
								className="bg-default text-sm"
								onClick={() => setSearch("")}
							>
								Clear
							</button>
						</div>
					</div>
					<div className="menu-display">
						{currentmenuGroup === menuGroup && _renderDisplayMenu()}
					</div>
					<div className="bg-default menu-save" onClick={handleSave}>
						Save
					</div>
				</div>
			)}
		</>
	);
}
export default AddBookingMenu;
