/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./payment-history.style.scss";

import Loader from "../../components/loader/loader.component";

import { useDispatch, useSelector } from "react-redux";
// import BookingHistoryTable from "../../components/tables/booking-history.table";
import PaginationReact from "../../components/paginationReact/paginationReact.component";

import {
	getPaymentHistory,
	searchPaymentHistory,
	setPaymentSearchParameters,
} from "../../redux/actions/payment-action";
import PaymentHistoryTable from "../../components/tables/payment-history.table";
import SearchPaymentHistoryComponent from "../../components/search/search-payment-history.component";
import { isEmptyObject } from "../../global/function";
import EditPaymentModal from "../../components/modals/edit-payment.modal";
import ExportCsvModal from "../../components/modals/export-csv-modal";
import DataDisplayBlock from "../../components/common/data-containers/data-display-block.component";
import SideModal from "../../components/modals/side-modal/side-modal.modal";
import moment from "moment";

export default function PaymentHistory(props) {
	const dataPerPage = 20;
	const dispatch = useDispatch();

	const [showEditModal, setShowEditModal] = useState(false);
	const [paymentInfo, setPaymentInfo] = useState({});
	const [currentPage, setCurrentPage] = useState(1);
	const [showModal, setShowModal] = useState(false);
	const [calculations, setCalculations] = useState({});
	const [showSideModal, setSideModal] = useState(false);
	const [sideModalData, setSideModalData] = useState({});

	const {
		isLoading,
		paymentHistoryList,
		paymentHistoryCount,
		paymentSearchParameters,
	} = useSelector((state) => state.payment);

	useEffect(() => {
		if (props.location.state) {
			let search = {
				...paymentSearchParameters,
				booking_id: props.location.state.booking_id,
			};
			dispatch(searchPaymentHistory(dataPerPage, currentPage, search));
		} else {
			if (isEmptyObject(paymentSearchParameters)) {
				dispatch(getPaymentHistory(dataPerPage, currentPage));
			} else {
				dispatch(
					searchPaymentHistory(
						dataPerPage,
						currentPage,
						paymentSearchParameters
					)
				);
			}
		}
	}, [dispatch, currentPage, dataPerPage]);

	useEffect(() => {
		//Efficiency issue: this happens twice on first load...
		if (!isLoading) {
			let temp = {
				discount: 0,
				refund: 0,
				payment: 0,
				total: 0,
			};

			paymentHistoryList.forEach((p) => {
				temp[p.transaction_type] =
					temp[p.transaction_type] + p.paid_amount;
			});

			temp.total = temp.payment - temp.refund - temp.discount;

			Object.keys(temp).forEach(
				(k) => (temp[k] = parseFloat(temp[k].toFixed(2)))
			);
			setCalculations({ ...temp });
		}
	}, [isLoading]);

	const onPageChange = (activePage) => {
		let currentPage = activePage.selected + 1;
		setCurrentPage(currentPage);
	};

	const toggleShowEditModal = (info) => {
		setPaymentInfo(info);
		setShowEditModal(true);
	};

	const toggleHideEditModal = () => {
		setShowEditModal(false);
	};

	const searchPayment = (searchData) => {
		setCurrentPage(1);
		dispatch(searchPaymentHistory(dataPerPage, 1, searchData));
	};
	const resetSearch = () => {
		let search = {
			payment_id: "",
			event_id: "",
			date_from: "",
			date_to: "",
			customer_fname: "",
			customer_lname: "",
			payment_type: "",
			transaction_type: "",
			venue_info_id: "",
			booking_id: "",
		};
		dispatch(setPaymentSearchParameters(search));
		dispatch(searchPaymentHistory(dataPerPage, 1, search));
		setCurrentPage(1);
	};

	const toggleShowModal = () => {
		setShowModal(true);
	};

	const toggleHideModal = () => {
		setShowModal(false);
	};

	return (
		<div className="main-container">
			{isLoading && <Loader />}
			<div className="inner-container full mt-44px">
				{/*  <SearchEmployeeComponent /> */}
				<SearchPaymentHistoryComponent
					searchPayment={searchPayment}
					resetSearch={resetSearch}
				/>
				<div className="payment-sum-block">
					<DataDisplayBlock
						data={calculations.payment}
						title={"Payments"}
						addStyle={{
							color: "white",
							backgroundColor: "var( --payment)",
						}}
					/>
					<DataDisplayBlock
						data={calculations.discount}
						title={"Discounts"}
						addStyle={{
							color: "white",
							backgroundColor: "var( --discount)",
						}}
					/>
					<DataDisplayBlock
						data={calculations.refund}
						title={"Refund"}
						addStyle={{
							color: "white",
							backgroundColor: "var( --refund)",
						}}
					/>
					<DataDisplayBlock
						data={calculations.total}
						title={"Total"}
						addStyle={{
							color: "white",
							backgroundColor: "var( --afternoon)",
						}}
					/>
				</div>
				<div className="payment-history-content">
					<span className="payment-history-title">
						Payment History
					</span>
					{paymentHistoryList.length > 0 ? (
						<PaymentHistoryTable
							paymentHistoryList={paymentHistoryList}
							onEditClick={toggleShowEditModal}
							currentPage={currentPage}
							dataPerPage={dataPerPage}
							paymentPrintView={(data) => {
								setSideModalData({
									customer_name: `${data.customer_fname} ${data.customer_lname}`,
									contact_info: data.customer_mobile,
									booked_date_nepali: data.booked_date_nepali,
									booked_date_english:
										data.booked_date_nepali,
									book_time: data.booked_time,
									customerEmail: "",
									booking_id: data.booking_id,
									payment_id: data.payment_id,
									transaction_type: data.transaction_type,
									payment_type: data.payment_type,
									payment_date: moment(data.createdAt).format(
										"YYYY-MM-DD"
									),
									paid_amount: data.paid_amount,
								});
								setSideModal(true);
							}}
						/>
					) : (
						<div className="no-data-display">
							<i className="far fa-frown" />
							No Data
						</div>
					)}
					<div className="table-controller-container">
						<PaginationReact
							totalCount={paymentHistoryCount}
							itemsPerPage={dataPerPage}
							onPageClick={onPageChange}
							forcePage={currentPage - 1}
						/>

						<button
							className="button-primary float-right md:hidden"
							onClick={toggleShowModal}
						>
							Export CSV
						</button>
					</div>
				</div>
			</div>

			<EditPaymentModal
				// handleShow={toggleShowEditModal}
				handleClose={toggleHideEditModal}
				isVisible={showEditModal}
				paymentInfo={paymentInfo}
			/>
			<ExportCsvModal
				filterData={paymentSearchParameters}
				handleShow={toggleShowModal}
				handleHide={toggleHideModal}
				isVisible={showModal}
				tableName="Payment"
			/>
			<SideModal
				isVisible={showSideModal}
				onModalClose={() => setSideModal(!showSideModal)}
				containerStyle={{ minWidth: "310px" }}
				title="Payment Preview"
				sideModalData={sideModalData}
				modelFor="payment"
			></SideModal>
		</div>
	);
}
