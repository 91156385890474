import { nepYear } from "./function";
import { API_URL, FILE_UPLOAD_URL, INVENOTRY_API_URL } from "./url";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
	url: API_URL,
	fileUploadUrl: FILE_UPLOAD_URL,
	inventoryURL: INVENOTRY_API_URL,
	gender: [
		{ id: "male", value: "Male" },
		{ id: "female", value: "Female" },
		{ id: "other", value: "Others" },
	],
	identification: [
		{ id: "driving licence", value: "Driving License" },
		{ id: "national id", value: "National ID Card" },
		{ id: "passport", value: "Passport" },
	],
	USERINFO_UPDATE_URL: `${API_URL}user/update`,
	COMPANY_UPDATE_URL: `${API_URL}companyInfo/update`,
	VENUE_UPDATE_URL: `${API_URL}venueInfo/update`,
	SETTINGS_UPDATE_URL: `${API_URL}winloss/update`,
	color: [
		"#CD78E2",
		"#C3C382",
		"#87CCD5",
		"#88A3D3",
		"#12235A",
		"#6D5855",
		"#7152BC",
		"#AB488B",
		"#0690A7",
		"#E78106",
	],
	nepaliYear: nepYear(),
	englishYear: [
		{ id: 2021, value: 2021 },
		{ id: 2022, value: 2022 },
		{ id: 2023, value: 2023 },
		{ id: 2024, value: 2024 },
		{ id: 2025, value: 2025 },
	],
	englishMonth: [
		{ id: 1, value: "Jan" },
		{ id: 2, value: "Feb" },
		{ id: 3, value: "Mar" },
		{ id: 4, value: "Apr" },
		{ id: 5, value: "May" },
		{ id: 6, value: "Jun" },
		{ id: 7, value: "Jul" },
		{ id: 8, value: "Aug" },
		{ id: 9, value: "Sept" },
		{ id: 10, value: "Oct" },
		{ id: 11, value: "Nov" },
		{ id: 12, value: "Dec" },
	],

	nepaliMonth: [
		{ id: 1, value: "Baisakh" },
		{ id: 2, value: "Jestha" },
		{ id: 3, value: "Ashar" },
		{ id: 4, value: "Shrawan" },
		{ id: 5, value: "Bhadra" },
		{ id: 6, value: "Ashoj" },
		{ id: 7, value: "Kartik" },
		{ id: 8, value: "Mangsir" },
		{ id: 9, value: "Poush" },
		{ id: 10, value: "Magh" },
		{ id: 11, value: "Falgun" },
		{ id: 12, value: "Chaitra" },
	],
	nepaliDay: [
		{ id: 1, value: "01" },
		{ id: 2, value: "02" },
		{ id: 3, value: "03" },
		{ id: 4, value: "04" },
		{ id: 5, value: "05" },
		{ id: 6, value: "06" },
		{ id: 7, value: "07" },
		{ id: 8, value: "08" },
		{ id: 9, value: "09" },
		{ id: 10, value: "10" },
		{ id: 11, value: "11" },
		{ id: 12, value: "12" },
		{ id: 13, value: "13" },
		{ id: 14, value: "14" },
		{ id: 15, value: "15" },
		{ id: 16, value: "16" },
		{ id: 17, value: "17" },
		{ id: 18, value: "18" },
		{ id: 19, value: "19" },
		{ id: 20, value: "20" },
		{ id: 21, value: "21" },
		{ id: 22, value: "22" },
		{ id: 23, value: "23" },
		{ id: 24, value: "24" },
		{ id: 25, value: "25" },
		{ id: 26, value: "26" },
		{ id: 27, value: "27" },
		{ id: 28, value: "28" },
		{ id: 29, value: "29" },
		{ id: 30, value: "30" },
		{ id: 31, value: "31" },
		{ id: 32, value: "32" },
	],

	paymentType: ["cash", "cheque", "transfer"],
	paymentTypeObj: [
		{ id: "cash", value: "Cash" },
		{ id: "cheque", value: "Cheque" },
		{ id: "transfer", value: "Transfer" },
	],
	itemTypes: [
		{ id: "veg", value: "Veg" },
		{ id: "beef", value: "Beef" },
		{ id: "boar", value: "Boar" },
		{ id: "buff", value: "Buff" },
		{ id: "chicken", value: "Chicken" },
		{ id: "duck", value: "Duck" },
		{ id: "fish", value: "Fish" },
		{ id: "lamb", value: "Lamb" },
		{ id: "mutton", value: "Mutton" },
		{ id: "pork", value: "Pork" },
		{ id: "dal", value: "Dal" },
		{ id: "staple", value: "Staple" },
		{ id: "bread", value: "Bread" },
		{ id: "other non-veg", value: "Other Non-Veg" },
		{ id: "other sea-food", value: "Other Sea Food" },
	],

	filteItemTypes: [
		{ id: "veg", value: "Veg", fileName: "veg" },
		{ id: "dal", value: "Dal", fileName: "dal" },
		{ id: "staple", value: "Staple", fileName: "staple" },
		{ id: "bread", value: "Bread", fileName: "bread" },
		{ id: "beef", value: "Beef", fileName: "beef" },
		{ id: "boar", value: "Boar", fileName: "boar" },
		{ id: "buff", value: "Buff", fileName: "buff" },
		{ id: "chicken", value: "Chicken", fileName: "chicken" },
		{
			id: "other non-veg",
			value: "Other Non-Veg",
			fileName: "otherNonVeg",
		},
		{ id: "duck", value: "Duck", fileName: "duck" },
		{ id: "fish", value: "Fish", fileName: "fish" },
		{ id: "lamb", value: "Lamb", fileName: "lamb" },
		{ id: "mutton", value: "Mutton", fileName: "mutton" },
		{ id: "pork", value: "Pork", fileName: "pork" },
		{
			id: "other sea-food",
			value: "Other Sea Food",
			fileName: "otherSeaFood",
		},
	],

	companyRole: [
		{
			id: "admin",
			value: "Admin",
		},
		{
			id: "manager",
			value: "Employee",
		},
		{
			id: "viewer",
			value: "Viewer",
		},
	],

	venueRole: [
		{
			id: "a_partner",
			value: "Active Partner",
		},
		{
			id: "p_partner",
			value: "Passvie Partner",
		},
		{
			id: "manager",
			value: "Manager",
		},
		{ id: "sales", value: "Sales" },
		{
			id: "viewer",
			value: "Viewer",
		},
	],

	adminRole: [
		{
			id: "a_partner",
			value: "Active Partner",
		},
		{
			id: "p_partner",
			value: "Passvie Partner",
		},
		{
			id: "manager",
			value: "Manager",
			disabled: true,
		},
		{ id: "sales", value: "Sales", disabled: true },
		{
			id: "viewer",
			value: "Viewer",
			disabled: true,
		},
	],

	employeeRole: [
		{
			id: "a_partner",
			value: "Active Partner",
			disabled: true,
		},
		{
			id: "p_partner",
			value: "Passvie Partner",
			disabled: true,
		},
		{
			id: "manager",
			value: "Manager",
		},
		{ id: "sales", value: "Sales" },
		{
			id: "viewer",
			value: "Viewer",
			disabled: true,
		},
	],

	viewerRole: [
		{
			id: "a_partner",
			value: "Active Partner",
			disabled: true,
		},
		{
			id: "p_partner",
			value: "Passvie Partner",
			disabled: true,
		},
		{
			id: "manager",
			value: "Manager",
			disabled: true,
		},
		{ id: "sales", value: "Sales", disabled: true },
		{
			id: "viewer",
			value: "Viewer",
		},
	],

	menuCartilage: [
		{ id: "NONE", value: "None" },
		{ id: "BL", value: "Boneless" },
		{ id: "WB", value: "With Bone" },
	],

	menuGroup: [
		{ id: "regular", value: "Regular" },
		{ id: "tea", value: "Tea and Breakfast" },
		{ id: "lunch", value: "Lunch" },
		{ id: "newari", value: "Newari Set" },
		{ id: "special", value: "Special" },
		{ id: "one", value: "Set One" },
		{ id: "two", value: "Set Two" },
		{ id: "three", value: "Set Three" },
	],

	categoryType: [
		{ id: "snacks", value: "Snacks" },
		{ id: "meals", value: "Meals" },
		{ id: "soup", value: "Soup" },
		{ id: "salad", value: "Salad" },
		{ id: "dessert", value: "Dessert" },
	],

	drinkCategory: [
		{ id: "alcohol", value: "Alcohol" },
		{ id: "cocktail", value: "Cocktail" },
		{ id: "cold", value: "Cold" },
		{ id: "hot", value: "Hot" },
		{ id: "mocktail", value: "Mocktail" },
	],

	drinkUnit: [
		{ id: "ml", value: "Milliliter (ML)" },
		{ id: "l", value: "Liter (L)" },
		{ id: "gal", value: "Gallon (gal)" },
	],

	drinkType: [
		{ id: "beer", value: "Beer" },
		{ id: "brandy", value: "Brandy" },
		{ id: "champagne", value: "Champagne" },
		{ id: "coffee", value: "Coffee" },
		{ id: "gin", value: "Gin" },
		{ id: "rum", value: "Rum" },
		{ id: "soft", value: "Soft" },
		{ id: "tea", value: "Tea" },
		{ id: "tequila", value: "Tequila" },
		{ id: "vodka", value: "Vodka" },
		{ id: "whiskey", value: "Whiskey" },
		{ id: "wine", value: "Wine" },
	],

	overrideType: [
		{ id: true, value: "Yes" },
		{ id: false, value: "No" },
	],

	cateringType: [
		{ id: "full catering", value: "Full Catering" },
		{ id: "half catering", value: "Half Catering" },
	],
	eventType: [
		{ id: "birthday", value: "Birthday" },
		{ id: "bratabandha", value: "Bratabandha" },
		{ id: "engagement", value: "Engagement" },
		{ id: "gufa", value: "Gufa" },
		{ id: "janku", value: "Janku" },
		{ id: "seminar", value: "Seminar" },
		{ id: "sharaddha", value: "Sharaddha" },
		{ id: "social event", value: "Social Event" },
		{ id: "wedding", value: "Wedding" },
	],
	venueNumber: [
		{ id: "1", value: "1" },
		{ id: "2", value: "2" },
		{ id: "3", value: "3" },
	],
	bookingStatus: [
		{ id: "hold", value: "Hold" },
		{ id: "inquiry", value: "Inquiry" },
		{ id: "process", value: "Process" },
		{ id: "booked", value: "Booked" },
		{ id: "confirmed", value: "Confirmed" },
		{ id: "pending", value: "Pending" },
		{ id: "complete", value: "Complete" },
		{ id: "canceled", value: "Canceled" },
	],
	paymentStatus: [
		{ id: "none", value: "none" },
		{ id: "exists", value: "exists" },
		{ id: "complete", value: "complete" },
		{ id: "refund", value: "refund" },
	],
	vat_status: [
		{ id: "none", value: "none" },
		{ id: "exists", value: "exists" },
		// { id: "c_vat", value: "vat" },
	],

	country: [{ id: "Nepal", value: "Nepal" }],
	city: [
		{ id: "Kathmandu", value: "Kathmandu" },
		{ id: "Bhaktapur", value: "Bhaktapur" },
		{ id: "Lalitpur", value: "Lalitpur" },
		{ id: "Chitwan", value: "Chitwan" },
		{ id: "Butwal", value: "Butwal" },
		{ id: "Pokhara", value: "Pokhara" },
	],
	province: [
		{ id: "p1", value: "Province No. 1" },
		{ id: "madhesh", value: "Madhesh " },
		{ id: "bagmati", value: "Bagmati" },
		{ id: "gandaki", value: "Gandaki" },
		{ id: "lumbini", value: "Lumbini" },
		{ id: "karnali", value: "Karnali" },
		{ id: "sudurpaschim", value: "Sudurpaschim" },
	],
	halls: [
		{ id: "1", value: "1" },
		{ id: "2", value: "2" },
		{ id: "3", value: "3" },
	],
	customerType: [
		{ id: "individual", value: "Individual" },
		{ id: "business", value: "Business" },
	],

	supportType: [
		{ id: "crash", value: "Crash" },
		{ id: "error", value: "Error" },
		{ id: "feature request", value: "Feature Request" },
		{ id: "help", value: "Help" },
	],
	supportPage: [
		{ id: "booking", value: "Booking" },
		{ id: "dashboard", value: "Dashboard" },
		{ id: "settings", value: "Settings" },
	],

	transactionType: [
		{ id: "payment", value: "Payment" },
		{ id: "discount", value: "Discount" },
		{ id: "refund", value: "Refund" },
	],
	paymentTypeDropDown: [
		{ id: "cash", value: "Cash" },
		{ id: "cheque", value: "Cheque" },
		{ id: "transfer", value: "Transfer" },
	],

	unitList: [
		{ id: "kg", value: "KG" },
		{ id: "ltr", value: "LTR" },
	],
	orderStatus: [
		{ id: "draft", value: "Draft" },
		{ id: "ordered", value: "Ordered" },
		{ id: "completed", value: "Completed" },
	],
	requestStatus: [
		{ id: "pending", value: "Pending" },
		{ id: "completed", value: "Completed" },
	],
	orderType: [
		{ id: "venue", value: "Venue" },
		{ id: "inventory", value: "Inventory" },
	],
	requestType: [
		{ id: "user", value: "Staff" },
		{ id: "venue", value: "Venue" },
		{ id: "booking", value: "Booking" },
	],

	vendorStatus: [
		{ id: "available", value: "Available" },
		{ id: "unavailable", value: "Unavailable" },
	],

	deleteMenu: {
		title: "Confirm Deletion",
		message: "Are you sure you want to delete this menu?",
	},
	deleteService: {
		title: "Confirm Deletion",
		message: "Are you sure you want to delete this service?",
	},
	deleteDrink: {
		title: "Confirm Deletion",
		message: "Are you sure you want to delete this drink?",
	},
	deleteBooking: {
		title: "Confirm Deletion",
		message:
			"Deleting booking will permanently remove all records of this booking!",
	},
	deleteCustomer: {
		title: "Confirm Deletion",
		message: "Are you sure you want to delete this customer?",
	},
	deleteEmployee: {
		title: "Confirm Deletion",
		message: "Are you sure you want to delete this user?",
	},
	deletePayment: {
		title: "Confirm Deletion",
		message: "Are you sure you want to delete this payment?",
	},
	deleteVatPayment: {
		title: "Confirm Deletion",
		message: "Are you sure you want to delete this vat payment?",
	},
	cancelBooking: {
		title: "Confirm Cancellation",
		message: "Are you sure you want to cancel this booking?",
	},
	reopenBooking: {
		title: "Confirm Reopen",
		message: "Are you sure you want to reopen this booking?",
	},
	changeEmpStatus: {
		title: "Change Employee Status",
		message: "Are you sure you want to change employee status?",
	},
	deleteVendor: {
		title: "Confirm Deletion",
		message: "Are you sure you want to delete this vendor?",
	},
	transferOwnership: {
		title: "Transfer Ownership",
		message: "Are you sure you want to transfer your admin ownership?",
	},
	deleteRequest: {
		title: "Delete Request",
		message: "",
	},
	deleteOrder: {
		title: "Delete Order",
		message: "",
	},
	completeBooking: {
		title: "Complete Booking",
		message: "Are you sure you want to complete this booking?",
	},
	addQueueBooking: {
		title: "Add Booking to Queue",
		message: "Do you want to add booking to queue?",
	},

	// addMenu: {
	// 	title: "Menu",
	// 	message:
	// 		"You do not have any items in the menu yet. Click the add button to start adding the Menu items.",
	// },

	addService: {
		title: "Services",
		message:
			"You do not have any Services selected yet. Click the Add button to start adding the Services.",
	},
	emailVerified: {
		title: "Email Verified",
		message:
			"Your email is verified. You can signin with mobile number as password",
	},
	emailVerifiedError: {
		title: "Failed to Verifiy Email",
		message:
			"Sorry! Failed to verify your email please try again after some time.",
	},
	updatePasswordModal: {
		title: "Update Password?",
		message:
			"After updating password, User will be logged out from all other devices",
	},
	forgotPasswordModal: {
		title: "Forgot Password?",
		message: "Complete following details to reset you password",
	},
	switchUser: {
		title: "Switch Company",
		message: "Choose a company to continue to CaterMe",
	},

	bookingVat: {
		title: "Booking VAT Bill",
		message: "Record VAT Bill for current bookings",
	},
	bookingPayment: {
		title: "Booking Payment",
		message: "Record Payments for current bookings",
	},

	eventVat: {
		title: "Event VAT Bill",
		message: "Record VAT Bill for multiple bookings",
	},

	eventPayment: {
		title: "Event Payment",
		message: "Record Payments for multiple bookings",
	},
	errorPage: {
		title: "You’ve Spotted A Bug!",
		subTitle:
			"We have dispatched a bug detective on it. It may run but it can’t hide",
	},
	pageNotFound: {
		title: "Whoa!",
		subTitle: "Seems like you found our secret page.",
	},

	//strings
	email_verification: "EMAIL VERIFICATION",
	email_verification_message:
		"Check your inbox for an email from Caterme. Click the link on the email to verify your email account and continue the registration process.",
	subscription_message:
		"Select how you would like to make payments for the services provided by the Caterme App. Note that you are able to change the subscription modal at any given time from the Company settings page.",
	supportModalTitle: "Contact Support",

	vatAdjustmentGuide:
		"Click Re-calculate VAT to get accurate breakdowns for your VAT bill",
};
