import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { sessionInfo } from "../../global/function";
import {
	closeShop,
	deactivateCompany,
	changeEmployeeRole,
} from "../../redux/actions/company-setting.action";
import TextInputWithIconComponent from "../common/text-input/text-input-with-icon.component";

Modal.defaultStyles.overlay.backgroundColor = "rgba(102,107,122,0.2)";
const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		padding: "0px",
		borderRadius: "10px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
	},
};

export default function ConfirmationModalOnInput({
	isVisible,
	handleClose,
	modalFor,
	modalTitle,
	modalMessage,
	handleBookingCancel,
}) {
	const dispatch = useDispatch();
	const [error, setError] = useState("");
	const [value, setValue] = useState("");

	async function _handleButton(buttonType) {
		let sessionData = await sessionInfo();
		let val = value.toLowerCase();
		let obj = {};

		//check if confirmation "Yes is entered"
		if (val === "yes") {
			setValue("");
			setError("");

			//Case check for dispatch
			switch (buttonType) {
				case "Deactivate":
					obj = {
						company_info_id: sessionData.company_info_id,
						company_deactivated: 1,
					};
					dispatch(deactivateCompany(obj));
					break;
				case "Close Shop":
					dispatch(closeShop());
					break;
				case "Switch Role":
					obj = {
						company_info_id: sessionData.company_info_id,
					};
					dispatch(changeEmployeeRole(obj));
					break;
				default:
					break;
			}
			handleClose();
		} else {
			setError("keyword does not match");
		}
	}

	// new code by sanat for confirmation of cancel booking with reason
	function bookingCancellation() {
		let val = value.toLowerCase();

		if (modalFor === "Cancel Booking" && val !== "") {
			handleBookingCancel(val);
		} else {
			setError("enter a valid reason to cancel booking");
		}
	}

	return (
		<Modal
			ariaHideApp={false}
			isOpen={isVisible}
			onRequestClose={handleClose}
			style={customStyles}
			contentLabel="Example Modal"
		>
			<div className="main-modal-wrapper deactivate-company bg-default">
				<div className="modal-title">{modalTitle}</div>
				<div className="modal-message">
					{modalTitle === "Cancel Booking"
						? "Are you sure to cancel this booking?"
						: `Confirm ${modalFor} Request: Type "Yes" below`}
				</div>
				<div className="-mt-7px flex justify-center">
					{modalTitle === "Cancel Booking" ? (
						<div className=" w-300">
							<TextInputWithIconComponent
								variant="form-lg edit"
								onChange={(e) => setValue(e.target.value)}
								value={value}
								error={error}
							/>
						</div>
					) : (
						<div className=" w-119">
							<TextInputWithIconComponent
								variant="form-md edit"
								onChange={(e) => setValue(e.target.value)}
								value={value}
								error={error}
							/>
						</div>
					)}
				</div>
				<div className="modal-message">{modalMessage}</div>
				<div className="modal-two-button-wrapper">
					<button
						className="button-primary"
						onClick={() => {
							setError("");
							handleClose();
						}}
					>
						Cancel
					</button>
					<button
						className={
							modalFor === "Deactivate"
								? "colored-button-wrapper bg-buttonFailed text-fontWhite"
								: "button-primary"
						}
						onClick={() =>
							modalTitle === "Cancel Booking"
								? bookingCancellation()
								: _handleButton(modalFor)
						}
					>
						{modalFor}
					</button>
				</div>
			</div>
		</Modal>
	);
}
